<template>
  <va-card title="View Organization">
    <va-input
      label="Name"
      v-model="organization.name"
      placeholder="Lafayette General "
      required
    >
      <va-icon slot="prepend" color="gray" name="fa fa-vcard" />
    </va-input>
    <va-input
      label="Address"
      v-model="organization.address"
      placeholder="3909 Ambassador Caffery Pkwy "
    >
      <va-icon slot="prepend" color="gray" name="fa fa-address-card" />
    </va-input>
    <va-input label="City" v-model="organization.city" placeholder="Lafayette ">
      <va-icon slot="prepend" color="gray" name="fa fa-address-card" />
    </va-input>

    <va-input
      label="Postal Code"
      v-model="organization.zip"
      placeholder="70508 "
    >
      <va-icon slot="prepend" color="gray" name="fa fa-vcard" />
    </va-input>
    <va-input
      label="Phone"
      v-model="organization.phone"
      placeholder="8662944599 "
    >
      <va-icon slot="prepend" color="gray" name="fa fa-phone" />
    </va-input>
    <va-select
      label="Country"
      v-model="organization.country"
      placeholder="i.e United States "
      @input="onCountryChange"
      :options="countries"
    />
    <va-select
      v-if="stateIsListed"
      :disabled="organization.country != 'United States'"
      label="State"
      v-model="organization.state"
      text-by="name"
      key-by="name"
      placeholder="i.e Louisiana "
      :options="states"
    >
    </va-select>
    <va-input
      v-else
      label="State"
      v-model="organization.state"
      placeholder="i.e Louisiana "
    ></va-input>

    <va-input
      class="mb-4"
      v-model="organization.comments"
      placeholder="... (Optional Field)"
      type="textarea"
      label="Comments"
    />
    <va-button
      class="justify-center"
      :disabled="!allFilledOut"
      @click="EditOrganization"
    >
      Update Organization
    </va-button>
    <va-button class="justify-center" @click="generalOverview()">
      Go Back
    </va-button>
  </va-card>
</template>

<script>
import states from "../../data/states.json";
import countries from "../../data/CountriesList";
import Edit from "./Edit.vue";
import axios from "@/scripts/interceptor.js";

export default {
  data() {
    return {
      countries,
      states: states,
      stateIsListed: true,
      organization: {
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phone: "",
        comments: "",
        active: true,
      },
      fromDatabase: {
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phone: "",
        comments: "",
        active: true,
      },
    };
  },
  async created() {
    if (!this.$route.params.uid) {
      this.$router.push("/organizations");
    }
    let response = await axios
      .get(`/api/organizations/admin/details/${this.$route.params.uid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.throwError(
          "Organization not found",
          "The organization you are looking for does not exist"
        );
      });
    if (!response.data) {
      this.throwError(
        "Organization not found",
        "The organization you are looking for does not exist"
      );
    }
    let data = {
      name: response.data.name,
      address: response.data.address,
      city: response.data.city,
      state: response.data.state,
      zip: response.data.zip,
      country: response.data.country,
      phone: response.data.phone,
      comments: response.data.comments,
      active: response.data.active,
    };
    this.organization = data;
    this.fromDatabase = { ...data };
    this.onCountryChange(true);
  },
  methods: {
    onCountryChange(skip = false) {
      if(!skip){
       this.organization.state = "";
      }
      this.stateIsListed = this.organization.country == "United States";
    },
    throwError(title, message) {
      this.$swal({
        title: title,
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
      }).then(() => {
        this.$router.push("/organizations");
      });
    },
    updateOrganization(data) {
      this.$swal({
        title: "Loading...",
        html: "Please, wait while we update the organization",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });
      axios
        .put(
          `/api/organizations/admin/details/edit/${this.$route.params.uid}`,
          data
        )
        .then((response) => {
          this.$swal({
            title: "Organization Updated",
            text: "The organization has been updated successfully",
            icon: "success",
            confirmButtonText: "Ok",
          });
          this.fromDatabase = { ...this.organization };
        })
        .catch((error) => {
          this.$swal({
            title: "Error",
            text: "An error occurred while updating the organization",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    EditOrganization() {
      //compare the this.fromDatabse and this.organization objects and only update the fields that have changed
      let changes = {};
      for (let key in this.organization) {
        if (this.organization[key] !== this.fromDatabase[key]) {
          changes[key] = this.organization[key];
        }
      }
      if (Object.keys(changes).length === 0) {
        this.$swal({
          title: "No Changes",
          text: "No changes have been made to the organization",
          icon: "info",
          confirmButtonText: "Ok",
        });
        return;
      }
      //show chagnes to user and ask for confirmation
      let message =
        "The following changes will be made to the organization: <br/><br/>";
      for (let key in changes) {
        let nameKey = changes[key];
        //check if key is an object
        if (typeof nameKey === "object") {
          nameKey = nameKey.name;
        }
        message += `<b>${this.capitalize(key)}</b>: ${nameKey} <br/>`;
      }
      this.$swal({
        title: "Confirm Changes",
        html: message,
        confirmButtonText: "Yes, Update",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((willUpdate) => {
        if (willUpdate) {
          this.updateOrganization(changes);
        }
      });
    },
    generalOverview() {
      this.$router.push({
        name: "edit",
        params: { uid: this.$route.params.uid },
      });
    },
  },
  computed: {
    allFilledOut() {
      const requiredFields = [
        "name",
        "address",
        "city",
        "state",
        "zip",
        "country",
        "phone",
      ];

      for (let i = 0; i < requiredFields.length; i++) {
        const field = this.organization[requiredFields[i]];
        if (field.length === 0) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
